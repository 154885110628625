import jquery from "jquery";
window.jQuery = window.$ = jquery;

// Bootstrap Framework
import "./scss/index.scss";
import "@popperjs/core";
import "bootstrap";

import "slick-carousel";
import hcSticky from "hc-sticky";
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock";

import simpleParallax from "simple-parallax-js";

//#region stickybar
document.addEventListener("DOMContentLoaded", function () {
  new hcSticky(".stickybar", {
    stickTo: "body",
    stickyClass: "stickybar--sticked",
  });
});
//#endregion

//#region offcavas mobile
$("[data-trigger]").on("click", function () {
  var trigger_id = $(this).attr("data-trigger");
  $(trigger_id).toggleClass("show");
  $("body").toggleClass("offcanvas-active");
});
$(".close-icon").on("click", function (e) {
  $(".navbar-collapse").removeClass("show");
  $("body").removeClass("offcanvas-active");
});
//#endregion

//#region sliders
$(".sliderhome").slick({
  infinite: true,
  slidesToShow: 1,
  autoplay: true,
  autoplaySpeed: 6000,
  dots: false,
  arrows: true,
  speed: 600,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        arrows: false,
        adaptiveHeight: false,
        autoplay: true,
        autoplaySpeed: 3000,
      },
    },
  ],
});
$(".sliderhome-mobile").slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  dots: true,
  asNavFor: ".sliderhome-mobile-nav",
});
$(".sliderhome-mobile-nav").slick({
  slidesToShow: 1,
  slidesToScroll: 1,
  asNavFor: ".sliderhome-mobile",
  arrows: false,
  dots: false,
  centerMode: false,
  focusOnSelect: true,
});
$(".slider-catalogue").slick({
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
  dots: true,
  arrows: false,
  centerPadding: '70px',
  responsive: [
    {
      breakpoint: 1450,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        arrows: false,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
        infinite: true,
        dots: true,
        arrows: false,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true,
        arrows: false,
      },
    },
  ],
});
//#endregion

//#region parallax
var image = document.getElementsByClassName("video-background__container");
var video = document.getElementsByClassName("parallax__img");
new simpleParallax(image, {
  delay: 0.1,
  transition: "cubic-bezier(0,0,0,1)",
  scale: 1.6,
});
new simpleParallax(video, {
  delay: 0.1,
  transition: "cubic-bezier(0,0,0,1)",
  scale: 1.6,
});
//#endregion
//#region show password
$(".show-pw-group a").on('click', function (event) {
  event.preventDefault();
  if ($('.show-pw-group input').attr("type") == "text") {
    $('.show-pw-group input').attr('type', 'password');
    $('.show-pw-group i').addClass("fa-eye");
    $('.show-pw-group i').removeClass("fa-eye-slash");
  } else if ($('.show-pw-group input').attr("type") == "password") {
    $('.show-pw-group input').attr('type', 'text');
    $('.show-pw-group i').removeClass("fa-eye");
    $('.show-pw-group i').addClass("fa-eye-slash");
  }
});
//#endregion

//#region input number
$(document).on('click', '.number-spinner button', function () {
  var btn = $(this),
    oldValue = btn.closest('.number-spinner').find('input').val().trim(),
    newVal = 0;

  if (btn.attr('data-dir') == 'up') {
    newVal = parseInt(oldValue) + 1;
  } else {
    if (oldValue > 1) {
      newVal = parseInt(oldValue) - 1;
    } else {
      newVal = 1;
    }
  }
  btn.closest('.number-spinner').find('input').val(newVal);
});
//#endregion
//#region toggle fatturazione
$('.check-fatturazione').on('click', function () {
  if ($(this).prop('checked')) {
    $(this).parents('.form__custom').find('.fatturazione-diversa').fadeIn('slow');
  } else
    $(this).parents('.form__custom').find('.fatturazione-diversa').fadeOut('slow');
});
//#endregion

//#region open single order
$('.btn-details').on('click', function () {
  if ($('.general-orders').css('display') != 'none') {
    $('.detailed-order').show().siblings('div').hide();
  } else if ($('.detailed-order').css('display') != 'none') {
    $('.general-orders').show().siblings('div').hide();
  }
});
//#endregion